#supplier-quality-management-container .supplier-v1-main-container {
  width: 100%;
  margin: 0px auto;
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  padding: 2px;
}
#supplier-quality-management-container .supplier-v1-main-container input[type='file'] {
  display: none;
}
#supplier-quality-management-container .supplier-v1-main-container input[type='radio'] {
  margin: none;
}
#supplier-quality-management-container .supplier-v1-main-container p {
  margin: 0px;
}
#supplier-quality-management-container .supplier-v1-main-container article p {
  width: 100%;
}
#supplier-quality-management-container .supplier-v1-main-container hr {
  margin: 0px;
}
#supplier-quality-management-container .supplier-v1-main-container ul {
  padding-left: 5px;
}
